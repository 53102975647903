import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import styled from "styled-components"

import "@fontsource/inter/400.css"
import "@fontsource/inter/700.css"

import Layout from "../components/layout"
import SEO from "../components/seo"

const colors = {
  baseType: '#2A3235',
  highlight: '#FFF500'
}

const Container = styled.div`
  display: flex;
  height: 100vh;
`

const Panel = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  @media (min-width: 768px) {
    width: 50%;
  }
`
const Body = styled.div`
  padding: 0 5%;
`

const Icon = styled.p`
  margin-bottom: 1.2rem;
  font-size: 3rem;
`

const Title = styled.h1`
  max-width: 800px;
  margin-bottom: 1.2rem;
  color: ${colors.baseType};
  font-family: "Inter";
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.1;
  @media (min-width: 768px) {
    font-size: 2.2rem;
    line-height: 1.2;
  }
`

const Link = styled.a`
  position: relative;
  display: inline-block;
  color: ${colors.baseType};
  text-decoration: none;
  border: 1px solid transparent;
  &::after {
    transition: all 0.10s ease;
    transform: scaleY(0.9);
    z-index: -1;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    content: '';
    background: ${colors.highlight};
    height: 15px;
    width: 100%;
  }
  &:focus {
    outline: none;
    border: 1px dashed ${colors.baseType};
  }
  &:hover,
  &:focus {
    color: #000;
    &::after {
      transform: scaleY(1);
      height: 100%;
    }
  }
`

const List = styled.ul``
const ListItem = styled.li`
  margin-right: 1.2rem;
  display: inline-block;
  font-family: "Inter";
  font-weight: 400;
  font-size: 1.4rem;
`
const Well = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: flex;
    width: 100%;
    align-items: center;
    width: 50%;
  }
`
const WellBody = styled.div`
  width: 100%;
`

const IndexPage = () =>{
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "austin.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Austin Starin" />
      <Container>
        <Panel>
          <Body>
            <Icon><span role="img"aria-label="peace sign">✌️</span></Icon>
            <Title>Hi, I’m Austin. I’m a software engineer working at <Link href="https://www.thisisarmor.com/" target="_blank" rel="noopener noreferrer">Armor</Link>. I’m passionate about building excellent user experiences.</Title>
            <List>
              <ListItem>
                <Link href="https://github.com/austinstarin" target="_blank" rel="noopener noreferrer">
                  GitHub
                </Link>
              </ListItem>
              <ListItem>
                <Link href="https://www.linkedin.com/in/austin-starin-8893a886/" target="_blank" rel="noopener noreferrer">
                  LinkedIn
                </Link>
              </ListItem>
            </List>
          </Body>
        </Panel>
        <Well>
          <WellBody>
            <Img fluid={data.placeholderImage.childImageSharp.fluid} alt="Austin Starin" />        
          </WellBody>
        </Well>
      </Container>
    </Layout>
  )
}

export default IndexPage
